@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
body {
  font-family: "Poppins", sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1 0 auto;
  display: flex;
  direction: row;
}
.App-link {
  color: #61dafb;
}

.routes-container {
  width: 100%;
}
